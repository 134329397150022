import React from "react"

// import Layout from "../components/layout"
import SEO from "../components/seo"
import rond_color from "../assets/rond_color.svg"
// import { Link } from "gatsby"

import Navigation from "../components/Navigation"


const plaizxwatizfr = () => (
    <div
        className="containerManifesto"
      >
        <div className=" container containerHome">
        <Navigation/>
        {/*<div className="rondTache row">
          <img src={rond} className="col-3 rond"/>
          <img src={tache} className="col-9 tache"/>
        </div>*/}
    <div>
    <SEO title="PLAIZ x WATIZ - Plaiz, le réseau social de la mode"/>
    <div>
    <h1 className="p1" style={{color:"white", marginTop:100}}>PLAIZ rejoint la famille WATIZ ✨</h1>

    <h4
      style={styles.backToHomeLink}
    >
    <p className="textChangeGameLeft" style={{marginBottom:100}}><br/>
          <b></b>
            <br/>

            À nos 50 000 utilisateurs, nous souhaitons vous dire merci. Après 5 années d’aventures, pendant lesquelles nous nous sommes levés tous les matins pour rendre encore meilleur le monde de la mode, pour aider chacun et chacune à être la version la plus stylée de soi-même, c’est avec joie et émotion qu’on vous annonce que Plaiz a été l’objet d’une acquisition par la meilleure application de mode française, j’ai nommé WATIZ.
            <br/><br/>
            <a target="_blank" href="https://watiz.io">WATIZ</a>,  c’est l’application mode en plein essor qui permet de retrouver un vêtement parmi un million de références avec un simple screenshot, shopper mais aussi s’inspirer grâce aux looks tendances disponibles.
            <br/><br/>
            Nous sommes convaincus que leur vision et leur ambition porteront les nôtres au-delà de nos rêves les plus audacieux. Dès notre rencontre, nous nous sommes pris d’admiration pour cette équipe, et nous avons décidé d’unir nos forces, pour que nous ayons la chance de contribuer au succès de WATIZ.
            <br/><br/>
            Pour notre communauté, cela signifie que nous allons interrompre les services de Plaiz en tant qu’application, sans possibilité de transférer vos comptes sur l’application WATIZ. Nous avons donc voulu vous proposer un délai d’un mois à compter du 25/08/2022 afin que vous sauvegardiez le contenu que vous avez créé sur Plaiz dont vous souhaiter continuer à disposer. Aucune de vos données ne sera conservée, ni par Plaiz, ni par Watiz. Passé ce délai (26/09/2022 à 00:00), vous n’aurez plus la possibilité d’accéder à vos données Plaiz et votre contenu.
            <br/><br/>
            Nous vous remercions une nouvelle fois d’avoir fait partie de la famille Plaiz, le gang, de nous avoir soutenu durant cette aventure, et pour ceux qui ne l’ont toujours pas fait, nous ne pouvons que vous encourager à télécharger <a target="_blank" href="https://watiz.io">WATIZ</a> et à en parler partout autour de vous, avant qu’on en parle partout autour de vous. De belles choses arrivent, restez branchés !
            <br/><br/>
            La team Plaiz

        </p>
        
    </h4>
    </div>
    </div>
    </div>
    </div>
)

export default plaizxwatizfr

const styles = {
  backToHomeLink: {
    color: "white",
  },
}